import { render, staticRenderFns } from "./Ppbpemda.vue?vue&type=template&id=6c1aae72"
import script from "./Ppbpemda.vue?vue&type=script&lang=js"
export * from "./Ppbpemda.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports